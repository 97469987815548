@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

li,
a,
.sidebar-brand {
  font-family: "Manrope";
}

.instagram-color .card-header{
background-color: #833AB4;
}